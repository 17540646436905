import './style.css';

//tiny calculator
let a;
let b;
let result;
const solution = document.getElementById('solution');

const divide = () => {
    a = document.getElementById('a').value;
    b = document.getElementById('b').value;

    if (b == 0) {
        solution.innerHTML = "geht ned";
        solution.classList.add('error');
    } else {
        result = a / b;
        solution.innerHTML = result;
        solution.classList.remove('error');
    }
};

const multiply = () => {
    a = document.getElementById('a').value; //das Element mit der ID "a" wird im Dokument gesucht und dessen Wert in der Variable a gespeichert
    b = document.getElementById('b').value;

    result = a * b;  //Der Wert von a wird mit dem Wert von b multipliziert und in der variable "result" gespeichert
    solution.classList.remove('error'); //der classlist vom Element mit der ID solution wird der Wert error entfernt
    solution.innerHTML = result;
};

const add = () => {
    a = document.getElementById('a').value;
    b = document.getElementById('b').value;

    if (isNaN(parseFloat(a)) || isNaN(parseFloat(b))) {
        result = "NaN";
    } else {
        result = parseFloat(a) + parseFloat(b);
    }
    solution.classList.remove('error');
    solution.innerHTML = result;

}

const subtract = () => {
    a = document.getElementById('a').value;
    b = document.getElementById('b').value;

    result = a - b;
    solution.classList.remove('error');
    solution.innerHTML = result;
}

const divideButton = document.getElementById('divide-button');
divideButton.addEventListener('click', divide);

const multiplyButton = document.getElementById('multiply-button');
multiplyButton.addEventListener('click', multiply);

const sumButton = document.getElementById('sum-button');
sumButton.addEventListener('click', add);

const subButton = document.getElementById('sub-button');
subButton.addEventListener('click', subtract);

//date&time
let today = new Date();
document.getElementById('date-field').innerHTML = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

let time = new Date();
document.getElementById('time-field').innerHTML = time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds();

//password generator
const lenghtEl = document.getElementById('lenght');
const upperEl = document.getElementById('uppercase');
const lowerEl = document.getElementById('lowercase');
const digitsEl = document.getElementById('digits');
const symbolEl = document.getElementById('symbols');

const pwButton = document.getElementById('pwButton');
const pwNew = document.getElementById('password');
const copyPw = document.getElementById('copy');

copyPw.addEventListener('click', (event) => {
    event.preventDefault();
});

let uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
let lowercase = "abcdefghijklmnopqrstuvwxyz";
let digits = "0123456789";
let symbol = "!@#$%^&*(){}[]=<>/,.|~?"

//functions for getting random upper-&lowercases, numbers, symbols
const getUpperCase = () => {
      return uppercase.charAt((Math.floor(Math.random() * uppercase.length)));
}

const getLowerCase = () => {
    return lowercase.charAt((Math.floor(Math.random() * lowercase.length)));
}

const getDigits = () => {
    return digits.charAt((Math.floor(Math.random()* digits.length)));
}

const getSymbol = () => {
    return symbol.charAt((Math.floor(Math.random()* digits.length)));
}

//functions to establish which characters have been ticked & randomly generate pw with those infos
const generatePw = () => {
    const length = lenghtEl.value;
    let password = "";

    for (let i = 0; i < length; i++) {
        const x = generateX(); // hier soll 1 zeichen zurück kommen
        password += x;
    }
        pwNew.innerText = password;
}

const generateX = () => {
    const xs = [];

    upperEl.checked && xs.push(getUpperCase());
    lowerEl.checked && xs.push(getLowerCase());
    digitsEl.checked && xs.push(getDigits());
    symbolEl.checked && xs.push(getSymbol());

    const randomPosition = Math.floor(Math.random() * xs.length);
    return xs[randomPosition];
}

pwButton.addEventListener('click', generatePw);
